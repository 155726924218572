




















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Game, Quiz, QuizTheme, QuizPhase, User } from '../../models';
import { defaultThemes, defaultQuizCountdown } from '../../defaults';
import QuizScreen from './QuizScreen.vue';

@Component({ components: { QuizScreen } })
export default class QuizStart extends Vue {
  @Prop({ required: true })
  readonly quiz!: Quiz;

  @Prop({ required: true })
  readonly game!: Game;

  color = 'blue';
  countdown = defaultQuizCountdown;
  counter = defaultQuizCountdown;
  timerId: number | undefined = undefined;
  QuizPhase = QuizPhase;

  get user(): User {
    return this.$store.getters['user/user'];
  }
  get theme(): QuizTheme {
    let theme = defaultThemes[this.quiz.theme];
    theme.image = this.quiz.background || theme.image;
    return theme;
  }

  created() {}

  startCountdown() {
    this.timerId = setInterval(this.tick, 1000);
  }
  tick() {
    this.counter--;
    if (this.counter === 0) {
      clearInterval(this.timerId);
      this.$emit('next');
    }
  }

  beforeDestroy() {
    clearInterval(this.timerId);
    this.timerId = undefined;
  }
}
