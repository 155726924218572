






























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { defaultThemes, defaultGame } from '../../defaults';
import { Quiz, QuizTheme, Game, Games, Participants, User } from '../../models';
import { quizService } from '../../services';

@Component
export default class QuizTile extends Vue {
  @Prop({ required: true })
  quiz!: Quiz;

  games: Games = [];
  participants: Participants = [];

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }
  get quizDuration(): number {
    return this.quiz.settings.timeRemaining || 0;
  }
  get participantsCount(): number {
    let participantsCount = 0;
    this.games.forEach((game) => (participantsCount += Object.keys(game.participants).length));
    return participantsCount;
  }
  get quizCompletion(): number {
    return 0;
  }
  get theme(): QuizTheme {
    let theme = defaultThemes[this.quiz.theme];
    theme.image = this.quiz.background || theme.image;
    return theme;
  }

  created() {
    this.$bind('games', quizService.getGamesRef(this.quiz.id));
    //this.$bind('participants', quizService.getParticipantsRef(this.quiz.id));
  }

  async cloneQuiz(quiz: Quiz) {
    try {
      await quizService.cloneQuiz(quiz, this.user);
      this.$bus.$emit('snackbar-notify', 'Quiz successfully cloned', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async deleteQuiz(quiz: Quiz) {
    try {
      await quizService.deleteQuiz(quiz.id);
      this.$bus.$emit('snackbar-notify', 'Quiz successfully deleted', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async playQuiz(quiz: Quiz) {
    //{ name: 'quiz-lobby', params: { id: quiz.id } }
    const game: Game = defaultGame(this.user);
    quizService.createGame(quiz.id, game);
    //await this.$router.push({ name: 'quiz-lobby', params: { id: quiz.id, gid: game.id } });
    await this.$router.push({ name: 'quiz-game-admin', params: { id: quiz.id, gid: game.id } });
  }
}
