


















import { Vue, Component, Prop } from 'vue-property-decorator';
import { QuizTheme } from '../../models';

@Component
export default class QuizScreen extends Vue {
  // @Prop({ default: 'transparent' })
  // color!: String;

  // @Prop({ default: null })
  // image!: String;

  // @Prop({ default: null })
  @Prop({ required: true })
  theme!: QuizTheme;

  get color(): string | null {
    if (!this.theme) return null;
    return this.theme.background!;
  }

  get image(): string | null {
    if (!this.theme) return null;
    return this.theme.image!;
  }

  get textColor(): string {
    if (!this.theme) return '';
    return this.theme.colors.text + ' !important';
  }
}
