



















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Quiz, QuizTheme, Games } from '../models';
import { defaultThemes, defaultQuestionTypes } from '../defaults';
import { quizService } from '../services';
import QuizScreen from '../components/quiz/QuizScreen.vue';
import QuizCard from '../components/quiz/QuizCard.vue';
import QuestionsList from '../components/question/QuestionsList.vue';
import GamesList from '../components/quiz/GamesList.vue';

@Component({
  components: {
    QuizScreen,
    QuizCard,
    QuestionsList,
    GamesList,
  },
})
export default class QuizInfoView extends Vue {
  @Prop({ required: true })
  id!: string;

  quiz: Quiz | null = null;
  games: Games = [];
  showAllChoices = false;
  showCorrectChoices = false;
  questionTypes = defaultQuestionTypes;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get isOwner(): boolean {
    return this.quiz !== null && this.quiz.owner === this.userId;
  }
  get theme(): QuizTheme {
    if (!this.quiz) return defaultThemes[0];
    let theme = defaultThemes[this.quiz.theme];
    theme.image = this.quiz.background || theme.image;
    return theme;
  }
  get quizDuration(): number {
    return this.quiz && this.quiz.questions ? Math.round(this.quiz.questions.length / 3) : 0;
  }

  @Watch('id', { immediate: true })
  async onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    try {
      await this.$bind('quiz', quizService.getQuizRef(id));
      await this.$bind(
        'games',
        quizService.getGamesRef(id).where('owner', '==', this.userId).orderBy('createdOn', 'desc')
      );
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }

  @Watch('quiz')
  onQuizChange(quiz: Quiz) {
    if (quiz) {
      document.title = quiz.title;
      this.$bus.$emit('loading-indicator', false);
      this.showCorrectChoices = this.isOwner;
    }
  }

  mounted() {
    this.$bus.$emit('title-change', 'Quiz', '/quiz');
  }
}
