



























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatPercent } from '@/utils';
import { Quiz, QuizTheme, Game, Participants, User } from '../../models';
import { defaultThemes } from '../../defaults';
import QuizScreen from '../quiz/QuizScreen.vue';
import GameSettings from '../quiz/GameSettings.vue';
import GameToolbar from '../quiz/GameToolbar.vue';
import QuestionResults from './QuestionResults.vue';

@Component({ components: { QuizScreen, GameSettings, GameToolbar, QuestionResults } })
export default class AdminQuestionEnd extends Vue {
  @Prop({ required: true })
  readonly quiz!: Quiz;

  @Prop({ required: true })
  readonly game!: Game;

  @Prop({ required: true })
  readonly participants!: Participants;

  showSettingsDialog = false;

  get user(): User {
    return this.$store.getters['user/user'];
  }
  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get isOwner(): boolean {
    return this.game ? this.userId === this.game.owner : false;
  }
  get theme(): QuizTheme {
    let theme = defaultThemes[this.quiz.theme];
    theme.image = this.quiz.background || theme.image;
    return theme;
  }
  get answeredParticipantIds(): string[] {
    const questionNumber = this.game.currentQuestion;
    if (!questionNumber || !this.game['responses']) return [];
    const question = this.quiz.questions[questionNumber - 1];
    const questionResponse = this.game.responses[question.id];
    return questionResponse ? Object.keys(questionResponse) : [];
  }
  get answeredPercent(): string {
    if (!this.participants.length) return '0';
    return formatPercent(this.answeredParticipantIds.length / this.participants.length);
  }
  get quizCompleted(): boolean {
    return this.game.currentQuestion !== null && this.game.currentQuestion >= this.quiz.questions.length;
  }
  get questionCompleted(): boolean {
    const all = this.answeredParticipantIds.length === this.participants.length;
    if (!all) return false;
    if (this.quizCompleted) {
      this.$bus.$emit('quiz-end');
    } else if (this.game.settings && this.game.settings.autoNextQuestion) {
      this.$bus.$emit('quiz-question-next');
    }
    return true;
  }

  participantAnswered(pid: string) {
    return this.answeredParticipantIds.includes(pid);
  }
  getParticipantColor(pid: string) {
    const defaultColor = this.user.id === pid ? 'accent' : 'default';
    return this.participantAnswered(pid) ? 'success' : defaultColor;
  }
}
