import type { User } from '@/models';
export { User };

export enum QuizPhase {
  QuizLobby = 'QuizLobby',
  //GetReady = 'GetReady',
  QuizStart = 'QuizStart',
  //QuestionReady = 'QuestionReady',
  QuestionStart = 'QuestionStart',
  QuestionEnd = 'QuestionEnd',
  QuizEnd = 'QuizEnd',
  Podium = 'Podium',
  //TimeUp = 'TimeUp',
  PlayAgain = 'PlayAgain',
  GameOver = 'GameOver',
}

export enum QuizTypes {
  Quiz = 'Quiz', //  a competitive multiple choice question game
  Jumble = 'Jumble', // a competitive sorting game where you have to place answers in correct order
  Survey = 'Survey', // a multiple choice game to gather opinions (no points)
  Discussion = 'Discussion', // a one question survey
}
export type QuizType = string;

export enum QuestionTypes {
  TrueFalse = 'TrueFalse',
  SingleChoice = 'SingleChoice',
  MultipleChoice = 'MultipleChoice',
  //Slide = 'Slide', // Pro feature
  //Poll = 'Poll', // Pro feature
}
//export type QuestionType = string;

export interface QuizTemplate {
  id: string;
  title: string;
  description?: string;
  icon?: string;
  color?: string;
  style?: string;
  questionTypes?: string[];
}
//export type QuizTemplates = QuizTemplate[];
export type QuizTemplates = Record<string, QuizTemplate>;

export interface QuizTheme {
  id: string;
  title: string;
  description?: string;
  background?: string;
  image?: string;
  colors: { text?: string; highlight?: string; background?: string; font?: string };
}
//export type QuizThemes = QuizTheme[];
export type QuizThemes = Record<string, QuizTheme>;

export interface QuestionType {
  id: string;
  class: string;
  title?: string;
  icon?: string;
}
//export type QuestionTypes = QuestionType[];

export enum MedalTypes {
  Gold = 'Gold',
  Silver = 'Silver',
  Bronze = 'Bronze',
  None = 'None',
}
export type MedalType = string;

export interface Participant {
  id: string;
  username: string;
  email: string;
  avatar?: string | null;
  lastLogon?: number | null;
}
export type Participants = Participant[];
export type ParticipantsMap = Record<string, Participant>;

export interface Category {
  title: string;
}
export type Categories = Category[];

export interface Tag {
  title: string;
  icon?: string;
  color?: string;
}
export type Tags = Tag[];

export type Favorites = string[];
export interface QuizStats {
  games: number;
  participants: number;
  totalQuestions: number;
  correctQuestions: number;
  favorites: Favorites;
}

export interface QuizSettings {
  timeRemaining: number | null;
  isPublic: boolean;
}

export interface QuestionSettings {
  timeRemaining: number | null;
}

export interface Quiz {
  id: string;
  title: string;
  description?: string | null;
  background?: string | null;
  type: QuizType;
  status: string;
  owner?: string;
  createdOn: number | null;
  createdBy: User;
  updatedOn?: number | null;
  updatedBy?: User | null;
  theme: string;
  category?: string;
  tags?: string[];
  settings: QuizSettings;
  stats: QuizStats;
  questions: Questions;
  image?: string | null;
  video?: string | null;
}
export type Quizzes = Quiz[];

export interface GameSettings {
  enableLeaderboard: boolean;
  enableMusic: boolean;
  enableSounds: boolean;
  enableTimer: boolean;
  enableStreakBonus: boolean;
  shuffleAnswers: boolean;
  shuffleQuestions: boolean;
  //autoJoinQuiz: boolean;
  autoNextQuestion: boolean;
}

export interface Game {
  id: string;
  status: string;
  owner: string;
  createdOn: number;
  createdBy: User;
  startedOn?: number | null;
  completedOn?: number | null;
  settings?: GameSettings;
  currentQuestion: number | null;
  phase: QuizPhase;
  participants: ParticipantsMap;
  responses: QuizResponses;
  ranking: Ranking;
  stats: Stats;
}
export type Games = Game[];

export interface Rank {
  username: string;
  avatar?: string | null;
  points: number;
  time: number;
}
export type Ranking = Rank[];

export interface UserStatistics {
  user: User;
  points: number;
  speed: number;
  streak: number;
  accuracy: number;
  correctQuestions: number;
  totalQuestions: number;
}
export type Stats = Record<string, UserStatistics>;

export interface Question {
  id: string;
  title: string;
  type: string; //QuestionType;
  choices: Choices;
  hint?: string;
  category?: Category | null;
  tags?: string[];
  image?: string | null;
  video?: string | null;
  time: number | null;
  points: number | null;
}
export type Questions = Question[];

export interface Choice {
  sequence?: number;
  title?: string;
  color?: string;
  image?: string | null;
  video?: string | null;
  correct?: boolean;
}
export type Choices = Choice[];

export type Answer = boolean | boolean[] | null; //string | number | boolean | string[] | number[] | boolean[] | null;
export type Answers = Answer[];

export interface UserResponse {
  question: string;
  answer: Answer;
  startTime?: number;
  endTime?: number;
  speed?: number;
  correct?: boolean;
}
export type QuestionResponse = Record<string, UserResponse>;
export type QuizResponses = Record<string, QuestionResponse>;
