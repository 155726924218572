




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  User,
  Participant,
  Participants,
  QuizPhase,
  Question,
  Answer,
  UserResponse,
  QuestionResponse,
  QuizTheme,
  Answers,
  UserStatistics,
} from '../models';
import { defaultQuiz, defaultGame, defaultThemes, defaultStats } from '../defaults';
import { quizService } from '../services';
import { calculateScore } from '../utils';
import QuizScreen from '../components/quiz/QuizScreen.vue';
import QuizLobby from '../components/quiz/QuizLobby.vue';
import QuizStart from '../components/quiz/QuizStart.vue';
import QuizRanking from '../components/quiz/QuizRanking.vue';
import QuestionStart from '../components/question/QuestionStart.vue';
import QuestionEnd from '../components/question/QuestionEnd.vue';

Component.registerHooks(['beforeRouteLeave']);

@Component({ components: { QuizScreen, QuizLobby, QuizStart, QuizRanking, QuestionStart, QuestionEnd } })
export default class QuizGameView extends Vue {
  async beforeRouteLeave(to, from, next) {
    await this.disconnect();
    next();
  }

  @Prop({ type: String, required: true })
  readonly id;

  @Prop({ type: String, required: true })
  readonly gid;

  quiz = defaultQuiz(this.user);
  game = defaultGame(this.user);
  QuizPhase = QuizPhase;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }
  get isReadOnly(): boolean {
    return this.game ? this.game.status === 'Closed' : false;
  }
  get isOwner(): boolean {
    return this.game.owner === this.userId;
  }
  get participants(): Participants {
    return this.game ? Object.values(this.game.participants) : [];
  }
  get participant(): Participant {
    const { id, username, email, avatar, lastLogon } = this.user;
    const participant: Participant = {
      id,
      username: username || 'Anonymous',
      email: email || 'anonymous@mail.com',
      avatar,
      lastLogon,
    };
    return participant;
  }
  get totalQuestions(): number {
    return this.quiz.questions.length;
  }
  get questionNumber(): number {
    return this.game.currentQuestion || 0;
  }
  get question(): Question | null {
    if (this.questionNumber === 0) return null;
    return this.quiz.questions[this.questionNumber - 1];
  }
  get answer(): Answer | null {
    if (
      !this.game ||
      !this.question ||
      !this.question.id ||
      !this.game.responses ||
      !this.game.responses[this.question.id] ||
      !this.game.responses[this.question.id][this.userId]
    ) {
      return null;
    } else {
      return this.game.responses[this.question.id][this.userId].answer;
    }
  }
  get theme(): QuizTheme {
    let theme = defaultThemes[this.quiz.theme];
    theme.image = this.quiz.background || theme.image;
    return theme;
  }
  get quizCompleted(): boolean {
    return this.questionNumber === this.quiz.questions.length;
  }
  get isCorrect(): boolean {
    if (!this.question || !this.answer) return false;
    const choices = this.question.choices.map((c) => c.correct);
    const answers = this.answer as Answers;
    for (let i = 0; i < answers.length; i++) {
      if (answers[i] !== choices[i]) return false;
    }
    return true;
  }
  get playerStats(): UserStatistics {
    return this.game.stats[this.userId] || defaultStats(this.user);
  }
  get playerRank(): Number | null {
    const sortedStats = Object.values(this.game.stats).sort((a, b) => b.points - a.points);
    const rank = sortedStats.findIndex((stat) => stat.user.id === this.userId);
    return rank >= 0 ? rank + 1 : null;
  }

  @Watch('id', { immediate: true })
  async onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    await this.$bind('quiz', quizService.getQuizRef(id));
  }

  @Watch('gid', { immediate: true })
  async onGidChange(gid: string) {
    await this.$bind('game', quizService.getGameRef(this.id, gid));
    await this.connectUser(this.participant);
    this.$bus.$emit('loading-indicator', false);
    if (this.quiz) {
      document.title = this.quiz.title;
    }
  }

  mounted() {
    this.$bus.$emit('title-change', 'Quiz', '/quiz');
  }

  created() {
    window.onbeforeunload = this.onBeforeUnload;
  }

  async connectUser(participant: Participant) {
    if (this.isReadOnly) return;
    await quizService.createParticipant(this.quiz.id, this.game.id, participant);
  }
  async disconnectUser(participant: Participant) {
    if (this.isReadOnly) return;
    await quizService.deleteParticipant(this.quiz.id, this.game.id, participant.id);
  }
  async disconnect() {
    await this.disconnectUser(this.participant);
  }

  checkCorrect(question: Question, answer: Answer): boolean {
    if (!question || !answer) return false;
    const choices = question.choices.map((c) => c.correct);
    const answers = Array.isArray(answer) ? answer : [answer];
    for (let i = 0; i < answers.length; i++) {
      if (answer[i] !== choices[i]) return false;
    }
    return true;
  }

  async onQuestionSubmit(answer: Answer, startTime: number, endTime: number, remainingTime: number) {
    if (!this.questionNumber || !this.question || !this.question.id || !this.user.id) return;
    const userId = this.user.id;
    const questionId = this.question.id;
    let gameResponses = this.game.responses;
    if (!gameResponses) gameResponses = {};
    let questionResponse = gameResponses[questionId] as QuestionResponse;
    if (!questionResponse) questionResponse = {};
    let userResponse = questionResponse[userId] as UserResponse;
    if (!userResponse) {
      const question = this.question.title;
      const speed = endTime - startTime;
      const correct = this.checkCorrect(this.question, answer);
      userResponse = { question, answer, startTime, endTime, speed, correct };
      //console.log('Question submitted', answer, startTime, endTime, remainingTime, userResponse);
      await quizService.updateGame(this.quiz.id, this.game.id, { [`responses.${questionId}.${userId}`]: userResponse });

      const questionPoints = this.question.points || 0;
      const questionTime = this.question.time || 0;
      const playerStats = this.playerStats;
      if (correct) {
        const userScore = calculateScore(questionPoints, questionTime, speed, playerStats.streak);
        playerStats.points = playerStats.points + userScore.totalScore;
        playerStats.speed = Math.round((playerStats.speed + speed) / 2); // TODO: Check if this calc is fine!
        playerStats.streak += 1;
        playerStats.correctQuestions = this.isCorrect ? playerStats.correctQuestions + 1 : playerStats.correctQuestions;
        playerStats.totalQuestions = this.questionNumber; //this.totalQuestions;
        playerStats.accuracy = playerStats.correctQuestions / playerStats.totalQuestions;
        //console.log('User statistics (CORRECT)', this.userId, playerStats, userScore, userResponse);
      } else {
        playerStats.streak = 0; // Reset streak on wrong answer!
        playerStats.totalQuestions = this.questionNumber; //this.totalQuestions;
        playerStats.accuracy = playerStats.correctQuestions / playerStats.totalQuestions;
        //console.log('User statistics (WRONG)', this.userId, playerStats, userResponse);
      }

      await quizService.updateGame(this.quiz.id, this.game.id, { [`stats.${userId}`]: playerStats });
    } else {
      this.$bus.$emit('snackbar-notify', 'Question already answered', 'error');
    }
  }
  async onQuestionTimeout(answer: Answer, startTime: number, endTime: number, remainingTime: number) {
    //console.log('Question timeout', answer, startTime, endTime, remainingTime);
    this.$bus.$emit('snackbar-notify', 'Question not answered on time', 'error');
    await this.onQuestionSubmit(answer, startTime, endTime, remainingTime);
  }

  onBeforeUnload() {
    this.disconnect();
  }
}
